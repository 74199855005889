import "./src/styles/global.css"
export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Website has been updated. ` +
        `Reload to display the latest version?`
    )

     if (answer === true) {
      window.location.reload()
    }
  }